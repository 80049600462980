@import '../../../variables.scss';

#commerce-product-page {
    padding-bottom: 30px;

    h1 {
        font-size: 17px;
        padding: 0;

        @media (min-width: 768px) {
            font-size: 25px;
        }
    }

    #breadcrumbs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        a {
            background-color: $primaryColor;
            color: $primaryColorText;
            border-radius: 15px;
            padding: 5px 10px;
            margin: 5px;

            &:hover {
                background-color: $accentColor;
            }

            &:last-child,
            &:last-child:hover {
                background-color: $accentColor;
            }
        }
    }

    #details {
        margin: 20px 20px;
        position: relative;
        // padding: 20px;
        #gifts {
            margin-top: 40px;
            background-color: $extraColor1;
            max-width: 100%;
            display: block;
            @media screen and (min-width: 767px) {
                display: none;
            }
            // color: white;
        }
        #media-side {
            .image-carousel {
                direction: ltr;
                position: relative;

                .yarl__container,
                .yarl__thumbnails_container {
                    background-color: #eee;

                    @media (min-width: 768px) {
                        background-color: #ffffff;
                    }
                }
                .yarl__thumbnails_thumbnail {
                    background-color: #fff;
                    border: 1px solid #eee;
                }
                .yarl__thumbnails_bottom .yarl__thumbnails_vignette,
                .yarl__thumbnails_top .yarl__thumbnails_vignette {
                    background: linear-gradient(to right, #eee 0, transparent 10% 90%, #eee 100%);

                    @media (min-width: 768px) {
                        background: linear-gradient(
                            to right,
                            #fff 0,
                            transparent 10% 90%,
                            #fff 100%
                        );
                    }
                }
                .yarl__button {
                    color: #aaa;
                    filter: drop-shadow(2px 2px 2px #fff);
                }
            }
        }
        .info-side {
            #gifts {
                display: block;
                max-width: 60%;
                @media screen and (max-width: 767px) {
                    display: none;
                }
            }
            .badges {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                flex-wrap: wrap;
                gap: 2px;
                // position: absolute;
                // top: 0;

                // margin-top: 10px;
                // margin: 10px 10px;
            }
            .brand {
                display: flex;
                align-items: center;
                max-width: fit-content;
                gap: 10px;
                margin: 10px 0;

                color: #aaa;
                img {
                    height: 40px;
                    width: auto;
                    margin: 0 auto;
                }
                .brand-name {
                    font-size: 24px;
                    font-weight: 400;
                }
            }
            .code-rating-wrapper {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 4px;
                color: #aaa;
                font-size: 14px;
                margin: 12px 0;
                .rating {
                    color: orange;
                }
                .code {
                    font-size: 14px;
                    display: flex;
                    justify-content: flex-start;
                    gap: 2px;
                }
            }

            .price-box {
                margin: 16px 0;

                .old-price {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 4px;
                    font-size: 14px;
                    .value {
                        color: #aaa;
                        text-decoration: line-through;
                    }
                    // margin: 12px 0;
                }
                .price {
                    font-size: 24px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 4px;

                    .title {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
                .saved {
                    background-color: $accentColor;
                    color: #ffffff;
                    font-weight: 500;
                    padding: 5px 10px;
                    border-radius: 50px;
                    display: inline-block;
                    font-size: 10px;
                }

                .saved-amount {
                    font-size: 14px;
                    color: $accentColor;
                    font-weight: 500;
                }

                .note {
                    color: #aaaaaa;
                    font-size: 10px;
                }
            }
            .quantity {
                border-color: $primaryColorLight;
                border-radius: 8px;
                input[type='number']::-webkit-outer-spin-button,
                input[type='number']::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input[type='number'] {
                    appearance: textfield;
                    -moz-appearance: textfield;
                }
            }
            .add-to-cart {
                .price {
                    font-size: 16px;
                }

                @media (max-width: 767px) {
                    background-color: #eee;
                    padding: 5px;
                    position: fixed;
                    bottom: 66px;
                    left: 0;
                    width: 100%;
                    z-index: 1;
                }

                @media (min-width: 768px) {
                    margin: 0 auto 20px;
                    flex-wrap: wrap;
                    align-items: center;
                }

                &.with-availability {
                    flex-basis: 100%;
                }

                span {
                    margin-top: 3px;
                    font-size: 20px;
                }

                input {
                    width: 97%;

                    border-radius: 0;
                    text-align: center;

                    @media (min-width: 768px) {
                        width: 70px;
                    }
                }
                .options {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 8px 0;
                    gap: 6px;

                    .name {
                        font-weight: 500;
                    }
                    input {
                        width: 15px;
                        cursor: pointer;
                    }
                }

                button {
                    width: 100%;
                    border: 0;
                    border-radius: 0;
                    font-size: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (min-width: 768px) {
                        width: auto;
                    }

                    &.btn-primary {
                        background-color: $accentColor;
                    }

                    img {
                        width: 20px;
                    }
                }

                i {
                    font-size: 20px;
                    line-height: 18px;
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: middle;
                }

                .alert {
                    flex-basis: 100%;
                }

                #availabilities {
                    flex-basis: 100%;
                    margin-top: 30px;

                    button {
                        background-color: $primaryColorLight;
                        display: inline-block;
                        margin: 5px;
                        padding: 5px;
                        color: #ffffff;
                        transition: background-color 0.5s;

                        &:hover,
                        &.selected {
                            background-color: #ffffff;
                            color: $primaryColorLight;
                        }
                    }

                    .react-datepicker {
                        width: 100%;

                        .react-datepicker__month-container {
                            float: none;
                        }
                    }
                }
            }
            .add-to-compare {
                @media (max-width: 767px) {
                    width: 50px !important;

                    div {
                        display: none;
                    }
                }
            }

            #seller {
                padding: 5px;
                display: inline-block;
                margin-top: 10px;
                font-weight: 600;

                a {
                    color: $accentColor;
                }
            }
        }
    }

    #sub-products {
        flex-shrink: 0;
        flex-grow: 0;

        @media (min-width: 768px) {
            width: 300px;
            margin-left: 15px;
        }

        > h4 {
            margin-bottom: 30px;
        }

        .product-row {
            display: flex;
            border-bottom: 2px solid #eeeeee;
            margin-bottom: 20px;
            position: relative;
            transition: background-color 0.5s;
            padding: 5px 0;

            &:hover {
                background-color: #eeeeee;
            }
        }

        .starting_from {
            display: none;
        }

        .image {
            background: right top no-repeat;
            background-size: contain;
            width: 100px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
        }

        .box-info {
            flex-grow: 1;
            display: flex;

            h4 {
                font-size: 15px;
                color: #000000;
            }

            p {
                color: #aaaaaa;
                font-size: 12px;
            }
        }

        .price {
            color: $primaryColor;
            font-size: 20px;
            font-weight: 500;
            width: 150px;
            flex-shrink: 0;

            span {
                color: #ccc;
                font-size: 8px;
                display: none;
            }

            .old-price {
                height: 25px;
                color: #ccc;
                font-size: 15px;
                text-align: center;
                text-decoration: line-through;
                display: inline-block;
                margin-left: 5px;
            }
        }

        .btn {
            background-color: $primaryColor;
            border: 0;
            width: 100%;
            font-size: 20px;
            color: #ffffff;
        }
    }
    
    .description img {
        height: auto !important;
    }

    #attributes-bar {
        display: flex;
        flex-wrap: wrap;

        .attribute {
            padding: 30px;
            margin: 10px;
            display: block;
            overflow: auto;
            border-radius: 10px;
            text-align: center;

            &.has_image {
                display: block;
            }

            img {
                // display: block;
                width: 100px;
                margin: 0 auto;
            }

            div {
                color: #777;
                display: inline-block;
                padding: 5px;
            }
        }
    }
    #tabs {
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding: 2px;
        color: #555555;

        .tabs-content {
            padding: 24px;
        }
        .attributes {
            margin-top: 10px;

            .row {
                border-bottom: 1px solid #eeeeee;
                margin: 0;
                padding: 10px 0;
                flex-wrap: nowrap;
            }
            .row > * {
                padding: 0;
            }

            i {
                color: $accentColor;
                margin-inline-end: 8px;
            }
            // I think this .description is not used
            .description {
                border: 0;

                .label {
                    display: none;
                }

                .value {
                    flex: 0 0 100%;
                    max-width: none;
                    padding: 0;
                }

                i {
                    display: none;
                }

                hr {
                    margin: 30px 0;
                }

                h2 {
                    font-size: 20px;
                }

                h4 {
                    color: $primaryColor;
                    font-size: 20px;
                }

                td {
                    vertical-align: top;

                    @media (max-width: 767px) {
                        display: block;
                        width: 100%;
                    }

                    img {
                        margin-bottom: 20px;
                    }
                }

                .features {
                    margin-top: 50px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;

                    @media (min-width: 768px) {
                        &:after {
                            content: '';
                            flex: auto;
                        }
                    }

                    span {
                        display: inline-block;
                        margin: 10px;
                        text-align: center;
                        opacity: 0.5;
                        transition: opacity 0.5s;
                        position: relative;
                        width: 65px;

                        &:after {
                            background-color: rgba(37, 139, 183, 0.3);
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 0;
                            height: 0;
                            transition:
                                width 0.5s,
                                height 0.5s;
                            border-radius: 50%;
                        }

                        &:hover {
                            opacity: 1;

                            &:after {
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }

                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }

                td {
                    border: 0;
                    text-align: justify;
                }
            }
        }

        .nav-tabs {
            background-color: #f3f4f8;
            border-bottom: 3px solid $primaryColor;
            flex-wrap: nowrap;
            padding: 0;

            .nav-item {
                color: #555555;
                text-align: center;
                font-size: 17px;
                font-weight: 200;

                @media (min-width: 768px) {
                    padding: 10px 10px;
                }
            }

            .nav-link {
                display: flex;
                align-items: center;
                border-radius: 0;

                &:hover {
                    background-color: $primaryColor;
                    color: #ddd;
                }
            }

            .nav-link.active {
                background-color: $primaryColor;
                border-radius: 0;
                color: $primaryColorText;
                border: 0;
            }
        }
        .review {
            padding: 10px;
            margin-top: 10px;
            border-bottom: 1px solid #eeeeee;

            .date {
                color: #aaaaaa;
                float: left;
                direction: ltr;
            }

            .rating {
                color: orange;
            }

            p {
                border-right: 1px solid #dddddd;
                padding-right: 5px;
                margin-top: 10px;
                color: #999999;
            }
        }
        .faq {
            padding: 10px;
            margin-top: 10px;
            border-bottom: 1px solid #eeeeee;

            p {
                border-right: 1px solid #dddddd;
                padding-right: 5px;
                margin-top: 10px;
                color: #999999;
            }
        }

        .tabs-desktop {
            display: none;
        }
        .tabs-mobile {
            display: block;
            .tabs-content {
                padding: 24px;
            }
        }
        @media screen and (min-width: 768px) {
            .tabs-desktop {
                display: block;
            }
            .tabs-mobile {
                display: none;
            }
        }
    }

    #reviews-summery {
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin: 20px 0;

        @media (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
        }

        h3 {
            width: 100%;
            font-size: 20px;
            font-weight: 600;
        }

        #summery {
            flex: 1 0;
            font-weight: 600;
            font-size: 15px;

            div {
                padding: 2px;
            }

            .line {
                display: flex;
                align-items: center;
                color: $primaryColor;
            }

            .progress {
                background-color: #f3f4f6;
                border-radius: 0;
                margin: 0 5px;
                flex-grow: 1;
                padding: 0;

                div {
                    background-color: $primaryColor;
                }
            }
        }

        #add-review {
            margin-top: 10px;

            @media (min-width: 768px) {
                margin-top: 0;
                padding: 0 50px;
                flex: 1 0;
            }

            .rating {
                color: #f3f4f6;
                direction: ltr;
                font-size: 50px;

                span {
                    padding: 1%;
                    cursor: pointer;
                }

                .active,
                span:hover:before,
                span:hover ~ span:before {
                    transition: color 0.5s;
                    color: $primaryColor;
                }
            }

            textarea {
                font-size: 15px;
                width: 100%;
            }
        }
    }

    .share {
        text-align: left;

        a {
            font-size: 25px;
            padding: 5px;
            display: inline-block;
        }

        input {
            display: block;
            direction: ltr;
            width: 100%;
        }
    }
}

// .rtl #commerce-product-page {
//     #details .add-to-cart {
//         margin-right: auto;

//         i {
//             margin-right: 0;
//             margin-left: 10px;
//         }
//     }
//     .price-box .saved {
//         margin-right: 0;
//         margin-left: 10px;
//     }
// }
