@import '../../../../variables.scss';

.products-list-block {
    background-color: #fff;
    padding: 15px;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;

    h3 {
        font-weight: 600;
        font-size: 23px;
        margin-bottom: 25px;
        flex-grow: 1;
        width: 100%;
    }

    .product-box-block {
        width: 48%;
        margin: 1%;

        @media (min-width: 768px) {
            width: 23%;
        }
    }

    .placeholder {
        width: 48%;
        margin: 1%;
    }
}

