@import '../../../../variables.scss';

.user-menu-block {
    position: relative;

    svg {
        width: 20px;
        margin-left: 5px;
        fill: currentColor;
    }

    button.btn.btn-primary.dropdown-toggle {
        background: transparent;
        line-height: 30px;
        font-size: 17px;
        transition: color 0.5s;
        border: 0;
        color: #000;
        padding: 0;
        display: flex;
        align-items: center;

        &:hover,
        &:active {
            color: $accentColor;
        }

        i {
            margin: 0 5px;
        }
    }

    .dropdown-menu.show {
        margin-top: 0;
        display: none;
    }

    &:hover .dropdown-menu.show {
        display: block;
        //transform: translate(0, 30px) !important;
    }

    a {
        transition: color 0.5s;
        text-decoration: none;
        font-size: 13px;
        display: flex;
        align-items: center;

        &:hover {
            color: $accentColor;
            text-decoration: none;
        }

        i {
            color: #999999;
            margin-right: 5px;
        }
    }

    .dropdown-menu {
        width: 200px;
        box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
        padding: 7px;
        border: 0;

        a:hover {
            background-color: #eeeeee;
        }
    }

    .dropdown-item:active {
        background-color: transparent;
    }
}

.rtl .user-menu-block {
    > .btn {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    a i {
        margin: 0 0 0 5px;
    }
}
