@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');
@import '../../variables.scss';

/*
 * Global styles
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
button {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
}

h1 {
    margin: 0;
    padding: 10px;
    font-size: 20px;
}

*:focus {
    outline: none;
    // border-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

img {
    max-width: 100%;
}

a {
    color: $bodyText;
    text-decoration: none;
}

body {
    background-color: $bodyColor;
    color: $bodyText;
    font-size: 14px;
    position: relative;

    @media (max-width: 767px) {
        padding-bottom: 70px;

        &:before {
            background-color: $bodyColor;
            width: 100%;
            height: env(safe-area-inset-top);
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
        }

        user-select: none;
    }
}

input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
}

/*
 * General styles
 */
#portal {
    display: flex;
    flex-direction: column;
}

#wrapper {
    flex: auto;
    display: flex;
    flex-direction: column;
}

#header {
    padding-top: 0;
    flex-direction: column;
    display: none;
    z-index: 2;

    .container {
        display: block;
    }

    @media (min-width: 768px) {
        display: block;
    }
}

#breadcrumbs {
    margin: 15px;

    i {
        font-size: 14px;
    }

    &,
    a {
        color: #919193;
    }
}

.no-results {
    padding: 40px 10px;
    text-align: center;
}

.switch-wrapper {
    position: relative;
    height: 100%;
}

.switch-wrapper > div {
    width: 100%;
    height: 100%;
    position: absolute;
}

.back-button {
    color: #ffffff;
    font-size: 29px;
}

.page-body {
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 20px;

    @media (min-width: 1200px) {
        margin: 20px auto;
    }
}

h1 + .page-body {
    margin-top: 0;
}

@media (max-width: 767px) {
    .is-admin .mobile-menu {
        margin-top: 40px;
    }
}

/*
 * mobile menu
 */
#mobile_menu {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    // position: absolute;
    position: fixed;
    top: 0;
    right: 100%;
    transition: right 0.5s;
    z-index: 2000;
    overflow: hidden;

    @media (min-width: 768px) {
        display: none;
    }

    &.active {
        right: 0;
    }

    .language-switcher-block a {
        color: inherit;
    }

    .user-menu-block {
        > button {
            display: none;
        }

        .dropdown-menu {
            display: block !important;
            position: static !important;
            transform: none !important;
            width: auto;
            box-shadow: none;
        }
    }
}

/*
 * Bootstrap override
 */
.container {
    max-width: 100%;

    @media (min-width: 1200px) {
        max-width: 1140px;
    }
}

.btn,
.dropdown-menu {
    font-size: inherit;
}

.btn-primary {
    background-color: $accentColor;
    border-color: $accentColor;
}

.dropdown-menu {
    margin: 0;
}

.modal {
    z-index: 2100;
}

.modal-backdrop {
    z-index: 2090;
}

.bootstrap-iso .chat--wrapper {
    bottom: 60px;
}

.form-check {
    padding-left: 1.5em;

    .form-check-input {
        margin-right: 0;
        margin-left: -1.5em;
        float: left;
    }
}

@media (max-width: 767px) {
    .container,
    .col-md-6,
    .row,
    .col-md-9 {
        padding: 0;
        margin: 0;
    }
}

/*
 * Libraries
 */
.alice-carousel__dots-item:hover,
.alice-carousel__dots-item.__active {
    background-color: $primaryColor;
    border: 0;
    width: 10px;
    height: 10px;
}

/*
 * RTL
 */
.rtl {
    text-align: right;
    direction: rtl;

    .form-check .form-check-input {
        margin-right: -1.5em;
        margin-left: 0;
        float: right;
    }
}

/* Scrollbar styles */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0) !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgba(208, 208, 226, 0.5);
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0) !important;
}

.pac-container {
    z-index: 9999 !important;
}